// Here you can add other styles

.buttons-space button + button {
  //margin: 0.25rem 0.125rem
}

ul.pagination li.page-item a {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-danger, .btn-info {
  color: #fff;
}

.swal2-container {
  z-index: 99999
}